import { select, selectAll, zoomIdentity } from "d3";
import { Edge as GraphlibEdge, Graph } from "graphlib";

import getIcon from "Components/icons/technologies";
import { getServiceIcon } from "Components/ServiceIcon/helper";

import type { Selection } from "d3";
import type { Deployment, DeploymentService } from "platformsh-client";

export interface NodeRendererOptions {
  interfaceId: string;
}
export type SVGRect = Selection<any, any, any, any>;
export type GGroup = Selection<any, any, any, any>;
export interface Edge extends GraphlibEdge {
  connectedNodes: Array<string>;
}
export class PshGraph extends Graph {
  hasAppEdges: boolean;
  hasServiceOverlap: boolean;

  width: number;
  height: number;

  constructor() {
    super();

    this.hasServiceOverlap = false;
    this.hasAppEdges = false;
    this.width = 0;
    this.height = 0;
  }
}

export type Worker = Record<string, Record<string, string>>;

export type RouteUrlDataType = {
  ssi: boolean;
  cache: boolean;
  service?: DeploymentService;
};

type Metadata = {
  kind: "router" | "app" | "service" | "worker";
  appName: string;
};

type MetadataSecondary = {
  type: string;
  name?: string;
  instancesCount: number;
  size?: string;
  disk?: number;
  service?: DeploymentService;
};

export type BaseTree = {
  id: string;
  icon?: string;
  label: string;
  width: number;
  height: number;
  line: number;
  column: number;
  iconColor: string;
  children: string[];
  metadata: Metadata;
};

export type RouterTree = BaseTree & {
  metadata: RouteUrlDataType;
};
export type AppTree = BaseTree & {
  metadata: {
    crons: Record<string, any>;
  } & MetadataSecondary;
};
export type ServiceTree = BaseTree & {
  metadata: {
    workers: DeploymentService[];
  } & MetadataSecondary;
};
export type WorkerTree = BaseTree & {
  metadata: {
    worker?: Worker;
  } & MetadataSecondary;
};

export type TreeBranchOption = RouterTree | AppTree | ServiceTree | WorkerTree;

export type Tree = Array<TreeBranchOption>;
export type TreeOptions = {
  gridStepX: number;
  gridStepY: number;

  sizeX?: number;
  sizeY?: number;

  maxHeight: number;
  arcLength: number;
  midLayerOffset: number;
  edgeMargin: number;

  treePositionY: number;

  nodeRenderer: (root: GGroup, node: TreeBranchOption) => GGroup;
  getNodeDimensions: () => { width: number; height: number };
};

export const createOrSelectGroup = (root: GGroup, name: string): GGroup => {
  let selection: GGroup = root.select("g." + name);
  if (selection.empty()) {
    selection = root.append("g").attr("class", name);
  }
  return selection;
};

export const generateAST = (data: Tree) => {
  const g = new PshGraph();
  g.setGraph({});

  let maxColumn: number = 0;
  let maxLine: number = 0;

  data.forEach(d => {
    g.setNode(d.id, {
      id: d.id,
      icon: d.icon,
      line: d.line,
      column: d.column,
      width: d.width,
      height: d.height,
      iconColor: d.iconColor,
      children: d.children,
      metadata: d.metadata
    });

    if (maxColumn < d.column) {
      maxColumn = d.column;
    }

    if (maxLine < d.line) {
      maxLine = d.line;
    }

    d.children.forEach(c => {
      g.setEdge(d.id, c, {});
    });
  });

  return { graph: g, sizeX: maxColumn, sizeY: maxLine };
};

// Need to return {width, height} of the node
export const getNodeDimensions = () => {
  const node = select(".label-container")?.node();
  return (node as SVGGraphicsElement)?.getBoundingClientRect();
};

export const nodeRenderer = () =>
  function (rootNode: GGroup, data: TreeBranchOption) {
    const { width, height, iconColor, metadata } = data;
    const iconSize = 20;
    const service = metadata?.service;
    const { isComposible, mainStackName, iconName, isRuntime } =
      getServiceIcon(service);

    const isGuaranteed = true;

    const rectNode = rootNode
      .insert("rect", ":first-child")
      .classed("label-container", true)
      .attr("transform", "rotate(-45)")
      .attr("rx", 8)
      .attr("ry", 8)
      .attr("x", -width / 2)
      .attr("y", -height / 2)
      .attr("width", width)
      .attr("height", height)
      .style("filter", "url(#shadow)");

    if ((metadata as { instancesCount: number })?.instancesCount > 1) {
      rootNode
        .insert("rect", ":first-child")
        .classed("label-container", false)
        .attr("transform", "rotate(-45)")
        .attr("rx", 8)
        .attr("ry", 8)
        .attr("x", -width / 2 + 5)
        .attr("y", -height / 2 + 5)
        .attr("width", width)
        .attr("height", height)
        .style("filter", "url(#shadow)");

      rootNode
        .insert("rect", ":first-child")
        .classed("label-container", false)
        .attr("transform", "rotate(-45)")
        .attr("rx", 8)
        .attr("ry", 8)
        .attr("x", -width / 2 + 10)
        .attr("y", -height / 2 + 10)
        .attr("width", width)
        .attr("height", height)
        .style("filter", "url(#shadow)");
    }
    rootNode
      .insert("g")
      .classed("icon", true)
      .html(
        `<image x="-${iconSize / 2}" y="-${
          iconSize / 2
        }" width="${iconSize}" height="${iconSize}" xlink:href="data:image/svg+xml;base64,${window.btoa(
          getIcon(
            service ? (service.type ? iconName : "router") : "router",
            -(iconSize / 2),
            -(iconSize / 2),
            iconSize,
            iconSize,
            iconColor
          )
        )}"></image>`
      );

    // Add the badge for the workers
    const hasWorker = (metadata as { worker: Worker })?.worker;
    const worker = rootNode.insert("g").classed("badge", true);

    if (hasWorker) {
      worker
        .style("transform", "translate(6px, -23px)")
        .append("circle")
        .attr("cx", 8)
        .attr("cy", 8)
        .attr("r", 8)
        .style("fill", "var(--mode-vector-primary-weakest)");
      worker
        .append("path")
        .attr(
          "d",
          "M12.2876 5.30078H10.875L10.0264 9.50635H9.96729L8.8877 5.30078H7.55566L6.47607 9.50635H6.42236L5.57373 5.30078H4.14502L5.60059 10.9995H7.09912L8.19482 6.94434H8.23779L9.3335 10.9995H10.8267L12.2876 5.30078Z"
        )
        .style("fill", "var(--theme-neutral-1000)");
    } else if (isComposible && isRuntime) {
      worker
        .append("circle")
        .attr("cx", 8)
        .attr("cy", 8)
        .attr("r", 10)
        .style("fill", "var(--theme-neutral-0)");
      worker
        .style("transform", "translate(7px, -21px)")
        .append("image")
        .attr(
          "xlink:href",
          `data:image/svg+xml;base64,${window.btoa(
            getIcon(mainStackName, 0, 0, 16, 16, "monochrome")
          )}`
        );
    }
    if (metadata.kind !== "router" && isGuaranteed) {
      const dAttribute =
        "M7.81836 8.12207H10.4844V11.7061C10.11 11.8298 9.72266 11.9258 9.32227 11.9941C8.92188 12.0625 8.47266 12.0967 7.97461 12.0967C7.2487 12.0967 6.63346 11.9535 6.12891 11.667C5.6276 11.3773 5.24674 10.959 4.98633 10.4121C4.72591 9.86198 4.5957 9.19954 4.5957 8.4248C4.5957 7.67936 4.74056 7.0332 5.03027 6.48632C5.31999 5.93619 5.74154 5.51139 6.29492 5.21191C6.84831 4.90918 7.51888 4.75781 8.30664 4.75781C8.69401 4.75781 9.06836 4.79688 9.42969 4.875C9.79427 4.94987 10.1279 5.05403 10.4307 5.1875L10.0205 6.15429C9.77962 6.04036 9.50944 5.94433 9.20996 5.86621C8.91048 5.78808 8.59961 5.74902 8.27734 5.74902C7.76628 5.74902 7.3252 5.8597 6.9541 6.08105C6.58626 6.30241 6.30306 6.61491 6.10449 7.01855C5.90592 7.41894 5.80664 7.89257 5.80664 8.43945C5.80664 8.97005 5.88639 9.43554 6.0459 9.83593C6.2054 10.2363 6.45443 10.5488 6.79297 10.7734C7.13477 10.9948 7.57585 11.1055 8.11621 11.1055C8.38639 11.1055 8.61589 11.0908 8.80469 11.0615C8.99349 11.0322 9.16764 10.9997 9.32715 10.9639V9.12304H7.81836V8.12207Z";
      if (hasWorker) {
        worker
          .append("circle")
          .style("transform", "translate(14px, 0px)")
          .attr("cx", 8)
          .attr("cy", 8)
          .attr("r", 8)
          .style("fill", "var(--mode-vector-primary-weakest)");
        worker
          .insert("path")
          .style("transform", "translate(14px, 0px)")
          .attr("d", dAttribute)
          .style("fill", "var(--theme-neutral-1000)");
      } else {
        rootNode
          .append("circle")
          .style("transform", "translate(6px, -23px)")
          .attr("cx", 8)
          .attr("cy", 8)
          .attr("r", 8)
          .style("fill", "var(--mode-vector-primary-weakest)");
        rootNode
          .insert("path")
          .style("transform", "translate(6px, -23px)")
          .attr("d", dAttribute)
          .style("fill", "var(--theme-neutral-1000)");
      }
    }
    return rectNode;
  };

// maximum treeWidth for large/medium padding
const LARGE_PADDING_THRESHOLD = 4;
const MED_PADDING_THRESHOLD = 7;
const MIN_NODE_WIDTH = 24;
const MAX_NODE_WIDTH = 57;

// Assign node positions
export const layout = (g: PshGraph, options: TreeOptions) => {
  const gridStepY = options.gridStepY;
  let gridStepX = options.gridStepX;

  // reduce padding for wide graphs
  if (options.sizeX && options.sizeX > LARGE_PADDING_THRESHOLD) {
    gridStepX = 80;
  }
  if (options.sizeX && options.sizeX > MED_PADDING_THRESHOLD) {
    gridStepX = 55;
  }

  g.nodes().forEach(n => {
    const node = g.node(n);

    node.x = node.column * gridStepX;
    node.y = node.line * gridStepY;
  });
};

// Draw nodes and attach events
export function draw(id: string, g: PshGraph, options: TreeOptions) {
  const svgGraph = select(`#${id}`);
  const svg = svgGraph.select("svg");

  const selection = createOrSelectGroup(
    createOrSelectGroup(svg.select("g"), "output"),
    "nodes"
  );
  let svgNodes = selection
    .selectAll("g.node")
    .data(g.nodes(), v => {
      return v as string;
    })
    .classed("update", true);

  svgNodes
    .enter()
    .append("g")
    .attr("class", "node")
    .attr("tabindex", 0)
    .attr("aria-labelledby", "node-tooltip")
    .attr("aria-haspopup", true)
    .attr("aria-expanded", false)
    .style("opacity", 0);

  svgNodes = selection.selectAll("g.node");

  svgNodes.each(function (v: string) {
    const node = g.node(v),
      thisGroup = select(this as SVGGraphicsElement);
    if (!node) {
      // The node is not in the graph anymore
      // We remove it from the DOM
      thisGroup.remove();
      return;
    }
    thisGroup
      .attr("class", node["class"])
      .attr(
        "class",
        `${thisGroup.classed("update") ? "update " : ""}node ${
          thisGroup.attr("class") || ""
        }`
      );

    node.elem = this;

    const { width } = node;

    if (node.id) {
      thisGroup.attr("id", node.id);
    }
    if (node.labelId) {
      thisGroup.attr("id", node.labelId);
    }

    // Add the rectangle
    // Remove the rect and icons in case of re-render
    thisGroup.selectAll("*").remove();

    // Add the rectangle to the SVG
    const rectNode = options.nodeRenderer(thisGroup, node);

    // Create and attach events
    const activate = () => {
      rectNode.style("filter", "url(#shadow-hover)");
      selectAll("." + v + "-path")
        .classed("highlight", true)
        .raise();
    };

    const deactivate = () => {
      rectNode.style("filter", "url(#shadow)");
      selectAll(".edgePath").classed("highlight", false);
    };

    const customEventFactory = (name: string) =>
      new CustomEvent(name, {
        detail: {
          x: node.x,
          y: node.y,
          size: width,
          metadata: node.metadata,
          class: node.class,
          icon: node.icon
        }
      });

    const onMouseout = () => {
      deactivate();
      (this as Element)?.dispatchEvent(customEventFactory("treeSvgOut"));
    };

    const onMouseover = () => {
      activate();
      (this as Element)?.dispatchEvent(customEventFactory("treeSvgOver"));
    };

    const onClick = () => {
      (this as Element)?.dispatchEvent(customEventFactory("treeSvgClick"));
    };

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.which === 13) {
        return (this as Element)?.dispatchEvent(
          customEventFactory("treeSvgClick")
        );
      }
    };

    thisGroup
      .on("focus", onMouseover)
      .on("blur", onMouseout)
      .on("mouseover", onMouseover)
      .on("mouseout", onMouseout)
      .on("click", onClick)
      .on("keydown", onKeyDown, false);
  });

  // We want to re-select the nodes because we could have removed some nodes
  svgNodes = selection.selectAll("g.node");

  return svgNodes;
}

// Position svg nodes on the DOM
export function positionNodes(selection: GGroup, g: PshGraph, id: string) {
  const created = selection.filter(function () {
    return !select(this).classed("update");
  });
  function translate(v: string) {
    const node = g.node(v);
    return "translate(" + node.x + "," + node.y + ")";
  }

  created.attr("transform", translate);

  selection.style("opacity", 1).attr("transform", translate);

  const svgGraph = select(`#${id}`);
  const svg = svgGraph.select("svg");

  const outputGroup = createOrSelectGroup(svg, "output");
  const shapeBBox = (outputGroup.node() as SVGGraphicsElement)?.getBBox();
  g.width = shapeBBox?.width;
  g.height = shapeBBox?.height;
}

// Assign values to edges before we render them
export const preProcessEdges = (g: PshGraph) => {
  g.nodes().forEach((n: string) => {
    const node = g.node(n);
    const edges = g.outEdges(n);

    let hasLeftEdge = false,
      hasRightEdge = false;

    edges?.forEach(e => {
      const target = g.node(e.w);
      const edge = g.edge(e);

      if (target.line === node.line) {
        edge.class = `same ${e.v}-path`; // PF-7723: quick and dirty solution to highlight workers' edge path
        return;
      }

      if (target.x < node.x) {
        edge.class = "left";
        hasLeftEdge = true;
      } else if (target.x > node.x) {
        edge.class = "right";
        hasRightEdge = true;
      } else {
        edge.class = "middle";
      }
    });

    const shouldStraightCross = hasLeftEdge && hasRightEdge;
    edges?.forEach(e => {
      g.edge(e).straightCross = shouldStraightCross;
    });
  });
};

// Scale the all graph to fit the container
export const scale = (id: string, g: PshGraph, options: TreeOptions) => {
  const graph = select(`#${id}`),
    svg = graph.select("svg"),
    inner = svg.select("g");

  // Remove any transform because we are measuring the node
  // in the actual DOM to do the calculations
  inner.style("transform", "");

  const node = inner.select(".node").node() as SVGGraphicsElement;

  // Center the graph
  const maxX = (graph.node() as HTMLElement)?.offsetWidth,
    maxY = (graph.node() as HTMLElement)?.offsetHeight;

  const padding = 5; // We need a minimum of padding to avoid clipping drop shadows.
  const nodeWidth = options.getNodeDimensions().width;

  let initialScale = Math.min(
    (maxX - 2 * padding) / g.width,
    (options.maxHeight - 2 * padding) / g.height,
    MAX_NODE_WIDTH / nodeWidth
  );

  // Modify scale if needed to maintain minimum node width
  initialScale = Math.max(initialScale, MIN_NODE_WIDTH / nodeWidth);

  // Since SVG translation is calculated without taking into account node padding on
  // edges of tree, calculate and add to final translation for centering
  const nodePaddingX =
    (node?.getBBox()?.width - nodeWidth * initialScale) / 2 || 0;

  const transformD3 = zoomIdentity
    .translate(
      (nodePaddingX + maxX - g.width * initialScale) / 2 + 10,
      options.treePositionY !== undefined
        ? options.treePositionY
        : (maxY - g.height * initialScale) / 2
    )
    .scale(initialScale);

  if (transformD3.x < 0) {
    //@ts-expect-error this shouldn't be mutated, but we are anyway
    transformD3.x = 0;
  }

  inner.style(
    "transform",
    `translate(${transformD3.x}px, ${transformD3.y}px) scale(${transformD3.k})`
  );

  svg
    .attr("width", maxX)
    .attr("height", maxY)
    .attr("xmlns", "http://www.w3.org/2000/svg");

  return { scale: initialScale, transform: transformD3 };
};

// Assign connected node for all edges
export const setHighlightPaths = (
  g: PshGraph,
  v: string,
  edges: Array<Edge>,
  visited: Set<string>
) => {
  const node = g.node(v);
  if (edges.length > 0) {
    edges.forEach(e => {
      if (!e.connectedNodes) {
        e.connectedNodes = [];
      }
      e.connectedNodes.push(v);
    });
  }
  if (node.children.length > 0) {
    node.children.forEach((w: string) => {
      if (!visited.has(w)) {
        const edge = g.edge({ v: v, w: w });
        // make recursive call with only current visited set
        const visitedCopy = new Set(visited);
        visitedCopy.add(v);
        setHighlightPaths(g, w, edges.concat(edge), visitedCopy);
      }
    });
  }
};

export const instanceCount = (count: unknown) => {
  if (typeof count === "number") {
    return count;
  }
  return 1;
};

export const iconColor = "var(--icon-slate-fill,var(--slate))";

export const getDeploymentRouter = (currentDeployment: Deployment) => {
  const primaryRoute = Object.values(currentDeployment?.routes || {}).find(
    route => route.primary
  );

  const router: RouterTree = {
    id: "router",
    icon: "router",
    label: "Router",
    width: 40,
    height: 40,
    line: 0,
    column: 0,
    iconColor,
    metadata: {
      appName: "routes",
      kind: "router",
      ssi: !!primaryRoute?.ssi?.enabled,
      cache: !!primaryRoute?.cache?.enabled
    },
    children: []
  };

  return router;
};

export const getDeploymentWebApps = ({
  name,
  currentLine,
  column,
  app,
  iconName
}: {
  name: string;
  currentLine: number;
  column: number;
  app: DeploymentService;
  iconName: string;
}) => {
  const a: AppTree = {
    id: name,
    icon: iconName,
    label: name,
    line: currentLine,
    width: 40,
    height: 40,
    column,
    iconColor,
    metadata: {
      service: app,
      appName: name,
      type: app?.type,
      size: app?.size,
      disk: app?.disk,
      name: app?.name,
      crons: app?.crons,
      kind: "app",
      instancesCount: instanceCount(app?.instance_count)
    },
    children: []
  };

  return a;
};

export const getDeploymentServices = ({
  name,
  type,
  currentLine,
  column,
  service
}: {
  name: string;
  type?: string;
  currentLine: number;
  column: number;
  service: DeploymentService;
}) => {
  const s: ServiceTree = {
    id: name,
    label: name,
    icon: type,
    line: currentLine,
    column,
    width: 40,
    height: 40,
    iconColor,
    children: [],
    metadata: {
      service,
      type: service.type,
      name: type,
      disk: service.disk,
      size: service.size,
      appName: name,
      kind: "service",
      workers: [],
      instancesCount: instanceCount(service.instance_count)
    }
  };

  return s;
};

export const getDeploymentWorkers = ({
  name,
  type,
  currentLine,
  column,
  worker
}: {
  name: string;
  type: string;
  currentLine: number;
  column: number;
  worker: DeploymentService;
}) => {
  const w: WorkerTree = {
    id: name,
    label: name,
    icon: type,
    line: currentLine,
    column,
    width: 40,
    height: 40,
    iconColor,
    children: [],
    metadata: {
      service: worker,
      appName: name,
      name: worker.name,
      type: worker.type,
      size: worker.size,
      disk: worker.disk,
      worker: worker?.worker,
      kind: "worker",
      instancesCount: instanceCount(worker.instance_count)
    }
  };

  return w;
};
